/**
 * 休暇区分
 */
export const EnumWorkClassification = {
  PAID_HOLIDAY: 90001, //有給休暇勤務分類ID
  PAID_HALF_HOLIDAY: 90002, //半有給休暇勤務分類ID
  SPECIAL_HOLIDAY: 90003, //特別休暇勤務分類ID
  OFFICIAL_HOLIDAY: 95000, //公休日分類ID
  SUBSTITUTE_HOLIDAY: 95001, //振休日分類ID
  SICKNESS_HOLIDAY: 95002, //傷病の休み分類ID
  ABSENCE_DAY: 97000, //欠勤ID
  OTHER: 99000 //その他
} as const;
export type EnumWorkClassification = typeof EnumWorkClassification[keyof typeof EnumWorkClassification];

export const EnumApprovalState = {
  UNAPPROVAL: "0",
  RESOLVE: "1",
  REJECT: "2",
  DURING: "3",
  WAITING: "4",
  NONE: ""
} as const;
export type EnumApprovalState = typeof EnumApprovalState[keyof typeof EnumApprovalState];

export const EnumCheckApproval = {
  No: "0",
  Yes: "1",
  NONE: ""
} as const;
export type EnumCheckApproval = typeof EnumCheckApproval[keyof typeof EnumCheckApproval];

export const Authority = {
  General: "0", //一般
  Leader: "1", //現場リーダー
  Manager: "2", //担当管理者
  Accountant: "5", //経理・事務
  Owner: "8", //経営陣
  SystemAdministrator: "9" //システム管理者
} as const;
export type Authority = typeof Authority[keyof typeof Authority];

export const ValidState = {
  Valid: "1",
  InValid: "0"
} as const;
export type ValidState = typeof ValidState[keyof typeof ValidState];

export const Zaikbn = {
  ZAI: "1",
  TAI: "2",
  KYU: "3"
} as const;
export type Zaikbn = typeof Zaikbn[keyof typeof Zaikbn];

export const WwsCode = {
  NoCalc: "0",
  Sunday: "1",
  Monday: "2",
  Tuesday: "3",
  Wednesday: "4",
  Thursday: "5",
  Friday: "6",
  Saturday: "7",
  ClosingDay: "9"
} as const;
export type WwsCode = typeof WwsCode[keyof typeof WwsCode];

export const OvdayCode = {
  NoOver: "0",
  More8Hours: "1",
  StandardHour: "2"
} as const;
export type OvdayCode = typeof OvdayCode[keyof typeof OvdayCode];

export const ExisteCode = {
  No: "0",
  Yes: "1",
  None: "99"
} as const;
export type ExisteCode = typeof ExisteCode[keyof typeof ExisteCode];
