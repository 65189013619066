
import Vue, { PropType } from "vue";
import { VSheet, VInput } from "vuetify/lib";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";

export default Vue.extend({
  name: "TimePicker2",
  components: { VSheet, VInput, VueTimepicker },
  props: {
    value: {
      type: String,
      required: false,
      default: ""
    },
    hideDetails: {
      type: [Boolean, String],
      required: false,
      default: false
    },
    dense: {
      type: Boolean,
      required: false,
      default: false
    },
    singleLine: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: ""
    },
    hourLabel: {
      type: String,
      required: false,
      default: "時"
    },
    minuteLabel: {
      type: String,
      required: false,
      default: "分"
    },
    placeholder: {
      type: String,
      required: false,
      default: "時間"
    },
    prependIcon: {
      type: String,
      required: false,
      default: undefined
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    rules: {
      type: Array,
      required: false,
      default: () => []
    },
    hourRange: {
      type: Array as PropType<number[] | [number[]]>,
      required: false,
      default: () => []
    },
    minuteRange: {
      type: Array as PropType<number[] | [number[]]>,
      required: false,
      default: () => []
    },
    secondRange: {
      type: Array as PropType<number[] | [number[]]>,
      required: false,
      default: () => []
    },
    closeOnComplete: {
      type: Boolean,
      required: false,
      default: false
    },
    autoScroll: {
      type: Boolean,
      required: false,
      default: false
    },
    minuteInterval: {
      type: Number,
      required: false,
      default: undefined
    },
    secondInterval: {
      type: Number,
      required: false,
      default: undefined
    },
    format: {
      type: String,
      required: false,
      default: "HH:mm"
    },
    dropDirection: {
      type: String as PropType<"auto" | "up" | "down">,
      required: false,
      default: "auto"
    }
  },
  computed: {
    modelValue: {
      get(): string {
        return this.value;
      },
      set(newValue: string) {
        this.$emit("input", newValue);
      }
    }
  }
});
