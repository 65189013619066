var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('v-row',{staticClass:"my-1"},[_c('v-col',{attrs:{"cols":"auto"}},[_c(_setup.TimeList,{attrs:{"headers":_setup.shiftHeader,"items":_vm.shift,"title":`シフト ${_vm.shift?.shiftName}`,"dense":""}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-divider',{attrs:{"vertical":""}})],1),_c('v-col',[_c('v-form',{attrs:{"readonly":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-chip',{attrs:{"dark":"","filter":_vm.dat.substituteShiftId === null,"color":_vm.dat.substituteShiftId === null
                ? 'green darken-2'
                : 'grey darken-1'}},[_vm._v(" "+_vm._s(_vm.dat.substituteShiftId === null ? "休暇申請" : "振替申請")+" ")])],1),(_vm.dat.substituteShiftId)?_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"value":_setup.formatDate(_vm.dat.substituteDate),"label":"振替日","filled":""}})],1)],1):_vm._e(),(
            _vm.dat.substituteShiftId &&
              _vm.shiftChange?.changeShiftName &&
              _vm.shiftChange?.changeShiftName != _vm.shiftPre?.preShiftName &&
              _vm.shiftChange?.changeShiftName != '振休'
          )?_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c(_setup.TimeList,{attrs:{"headers":_setup.preShiftHeader,"items":_vm.shiftPre,"title":`変更前`,"dense":""}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"auto","align-self":"center"}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-arrow-right-thick")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c(_setup.TimeList,{attrs:{"headers":_setup.changeShiftHeader,"items":_vm.shiftChange,"title":`変更後`,"dense":""}})],1)],1):_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-radio-group',{attrs:{"value":_vm.dat.vacationKbn,"row":"","mandatory":"","label":"休暇区分","hide-details":""}},_vm._l((_setup.vacationKbn),function(kbn,i){return _c('v-radio',{key:i,attrs:{"label":kbn.text,"value":kbn.value}})}),1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-radio-group',{attrs:{"value":_vm.dat.resonKbn,"row":"","mandatory":"","label":"事由","hide-details":""}},_vm._l((_setup.reasonKbn),function(kbn,i){return _c('v-radio',{key:i,attrs:{"label":kbn.text,"value":kbn.value}})}),1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"value":_vm.dat.resonText,"label":"理由","filled":""}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticStyle:{"text-align":"right","font-size":"1.2em"}},[_vm._v(" "+_vm._s(_setup.displayStatus(_vm.dat))+" ")])])],1)],1),_c('v-card',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_setup.approvalHeaders,"items":_vm.approvals,"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.approvalDate",fn:function({ item }){return [_vm._v(" "+_vm._s(_setup.formatDateTime(item.approvalDate))+" ")]}},{key:"item.approvalFlag",fn:function({ item }){return [_c(_setup.ApprovalChip,{attrs:{"value":item.approvalFlag}})]}}])})],1),_c('v-divider'),_c('v-card-actions',[_vm._t("actions")],2)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }