<template>
  <DialogBase
    visible
    ref="dialog"
    title="応援登録"
    icon="mdi-trumpet"
    fullscreen
    midium
    headerButtonDisp
    @clickClose="close(false)"
  >
    <v-form ref="form">
      <v-row>
        <!-- <v-col colspan="4">
        <DatePicker
          v-model="targetdate"
          label="対象年月日"
          prepend-icon="mdi-calendar"
          type="day"
          outlined
          max-width="300"
        />
      </v-col> -->
        <v-col cols="12">
          <v-autocomplete
            v-model="selectDays"
            :items="days"
            outlined
            multiple
            clearable
            :rules="[Rules.Required]"
            label="日付"
            item-text="name"
            item-value="code"
            chips
          ></v-autocomplete>
        </v-col>
        <v-col colspan="4" class="editable-control">
          <v-autocomplete
            v-model="user"
            :readonly="displayType == Selects.User"
            :items="users"
            outlined
            label="社員"
            item-text="name"
            item-value="code"
            prepend-icon="mdi-form-select"
            return-object
            max-width="300"
          >
          </v-autocomplete>
        </v-col>
        <v-col colspan="4" class="editable-control">
          <v-autocomplete
            v-model="shozoku"
            :readonly="displayType == Selects.Office"
            :items="shozokus"
            label="所属"
            :rules="[Rules.Required]"
            outlined
            item-text="name"
            item-value="code"
            prepend-icon="mdi-form-select"
            return-object
            max-width="300"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col colspan="2.4">
          <TimePicker
            v-model="starttime"
            label="開始時間"
            prepend-icon="mdi-clock"
            :rules="[Rules.Required]"
            outlined
          />
        </v-col>
        <v-col colspan="2.4">
          <TimePicker
            v-model="endtime"
            label="終了時間"
            prepend-icon="mdi-clock"
            :rules="[Rules.Required]"
            outlined
          />
        </v-col>
        <v-col colspan="2.4">
          <v-text-field
            v-model.number="amount"
            label="回数単価"
            suffix="円"
            type="number"
            step="1"
            prepend-icon="mdi-wallet"
            outlined
          ></v-text-field>
        </v-col>
        <v-col colspan="2.4">
          <v-text-field
            v-model.number="reserveAmount1"
            label="時給単価"
            suffix="円"
            type="number"
            step="1"
            prepend-icon="mdi-wallet"
            outlined
          ></v-text-field>
        </v-col>
        <v-col colspan="2.4">
          <v-text-field
            v-model="biko"
            label="備考"
            prepend-icon="mdi-wallet"
            outlined
            :rules="[
              value =>
                !!value || shozokuCode !== '0' || '備考を入力してください'
            ]"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <div class="d-flex flex-row-reverse mt-5">
      <v-btn color="primary" @click="setdata">
        設定
      </v-btn>
      <v-btn color="#fff" @click="close(false)" class="mx-10">
        閉じる
      </v-btn>
    </div>
    <template v-if="isShow()" class="mt-10">
      <table>
        <thead>
          <tr>
            <th v-for="(header, i) in setItemHeader" :key="i">
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in setitems" :key="index">
            <td>{{ displayDate(item.targetDate) }}</td>
            <td>{{ item.employeeName }}</td>
            <td>{{ item.affiliationName }}</td>
            <td>
              {{ item.starttime }}
            </td>
            <td>{{ item.endtime }}</td>
            <td>{{ item.amount }}</td>
            <td>{{ item.reserveAmount1 }}</td>
            <td>{{ item.biko }}</td>
            <td>
              <v-icon
                small
                class="ml-1"
                color="error"
                @click="deleteRow(index)"
              >
                mdi-delete
              </v-icon>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex flex-row-reverse mt-5">
        <v-btn type="login" color="success" @click="regist">
          登録
        </v-btn>
      </div>
    </template>
    <v-card outlined class="mt-5">
      <v-expansion-panels flat v-model="panel">
        <v-expansion-panel>
          <!-- Header -->
          <v-expansion-panel-header>
            <span class="headline">当月応援登録済データ</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
              :headers="dataheaders"
              :items="databodies"
              :items-per-page-options="5"
              class="elevation-1"
            ></v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </DialogBase>
</template>
<script>
import Common from "@/mixins/common";
import Dialog from "@/mixins/dialog";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import AttendanceAssist from "@/models/AttendanceAssist";
import "vue-good-table/dist/vue-good-table.css";
import moment from "moment";

moment.locale("ja", {
  weekdays: [
    "日曜日",
    "月曜日",
    "火曜日",
    "水曜日",
    "木曜日",
    "金曜日",
    "土曜日"
  ],
  weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
});

const DATE = new Date();
const DATENOW =
  DATE.getFullYear() +
  "-" +
  ("00" + Number(DATE.getMonth() + 1)).slice(-2) +
  "-" +
  ("00" + Number(DATE.getDate())).slice(-2);

export default {
  name: "AttendanceAssistDialog",
  mixins: [Common, Dialog, Forms, Api, ShowDialogs],
  data: () => ({
    targetdate: DATENOW,
    displayType: "",
    selectDays: [],
    days: [],
    user: "",
    shozoku: "",
    users: [],
    shozokus: [],
    starttime: "00:00",
    endtime: "00:00",
    amount: 0,
    reserveAmount1: 0,
    biko: "",
    setItemHeader: [
      "日付",
      "社員",
      "所属",
      "開始時間",
      "終了時間",
      "回数単価",
      "時給単価",
      "備考",
      "action"
    ],
    setitems: [],
    dataheaders: [],
    databodies: [],
    panel: 0,
    Selects: {
      User: 0,
      Office: 1,
      Me: 2
    }
  }),
  computed: {
    shozokuCode() {
      return this.shozoku.code ? this.shozoku.code : this.shozoku;
    }
  },
  methods: {
    close(result) {
      this.$store.commit("user/logout");
      this.resolve(result);
      this.$close();
    },
    isShow() {
      return this.setitems.length > 0;
    },
    setdata() {
      console.log("setdata");
      if (!this.$refs.form.validate()) return;
      const userincode = this.user.code ? this.user.code : this.user;
      const selectUser = this.users.filter(e => e.code === userincode);
      const shozokuincode = this.shozoku.code
        ? this.shozoku.code
        : this.shozoku;
      const selectShozoku = this.shozokus.filter(e => e.code === shozokuincode);
      this.selectDays.forEach(day => {
        const item = {
          targetDate: day,
          employeeId: selectUser[0].code,
          employeeName: selectUser[0].name,
          allifiationId: selectShozoku[0].code,
          affiliationName: selectShozoku[0].name,
          starttime: this.starttime,
          endtime: this.endtime,
          amount: this.amount,
          reserveAmount1: this.reserveAmount1,
          biko: this.biko
        };
        this.setitems.push(item);
      });
    },
    displayDate(targetDate) {
      const dt = targetDate.split("-");
      return dt[0] + "年" + dt[1] + "月" + dt[2] + "日";
    },
    async regist() {
      console.log("regist");
      this.$loading();
      try {
        const param = [];
        this.setitems.forEach(e => {
          const item = new AttendanceAssist(
            e.targetDate,
            e.employeeId,
            e.allifiationId,
            e.starttime,
            e.endtime,
            e.amount,
            e.reserveAmount1,
            e.biko
          );
          param.push(item);
        });

        await this.$post(this.Paths.attendanceAssist, param);
        this.$info("更新しました。");
        this.setitems = [];
        this.$store.commit("user/logout");
        this.resolve(true);
        this.$close();
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async deleteRow(index) {
      console.log("deleteRow", index);
      if (await this.deleteConfirm()) {
        this.setitems = this.setitems.filter((x, i) => i !== index);
      }
    },
    async deleteConfirm() {
      return await this.$deleteConfirm("削除します。\nよろしいですか？");
    },
    validate() {
      if (this.displayType === 1 && this.user === "") {
        this.$warning("社員を選択してください");
        return false;
      }
      if (this.displayType === 0 && this.shozoku === "") {
        this.$warning("所属を選択してください");
        return false;
      }
      if (this.starttime === "") {
        this.$warning("開始時間を設定してください");
        return false;
      }
      if (this.endtime === "") {
        this.$warning("終了時間を設定してください");
        return false;
      }
      const dt = new Date();
      const start = new Date(
        dt.getFullYear(),
        dt.getMonth() + 1,
        dt.getDate(),
        this.starttime.split(":")[0],
        this.starttime.split(":")[1],
        0
      );
      const end = new Date(
        dt.getFullYear(),
        dt.getMonth() + 1,
        dt.getDate(),
        this.endtime.split(":")[0],
        this.endtime.split(":")[1],
        0
      );
      if (
        this.starttime !== "00:00" &&
        this.endtime !== "00:00" &&
        end <= start
      ) {
        this.$warning("開始時間と終了時間を設定してください");
        return false;
      }
      return true;
    },
    async setDays(fromdate, todate) {
      const fromMoment = moment(fromdate).subtract(1, "month");
      const toMoment = moment(todate).subtract(1, "month");
      while (fromMoment.diff(toMoment) <= 0) {
        this.days.push({
          code: fromMoment.format("YYYY-MM-DD"),
          name: fromMoment.format("YYYY-MM-DD (ddd)")
        });
        fromMoment.add(1, "days");
      }
      //const targetFrom = fromdate;
      //const dayofweek = ["日", "月", "火", "水", "木", "金", "土"];
      //while (targetFrom <= todate) {
      /*const y = targetFrom.getFullYear();
        const m = ("00" + Number(targetFrom.getMonth() - 1)).slice(-2);
        const d = ("00" + targetFrom.getDate()).slice(-2);
        const date = new Date(y, m, d);*/

      //  const y = [];
      //  const m = [];
      //  const d = [];
      //  y[0] = targetFrom.getFullYear();
      //  m[0] = ("00" + Number(targetFrom.getMonth() - 1)).slice(-2);
      // d[0] = ("00" + targetFrom.getDate()).slice(-2);
      //  const date = new Date(y[0], m[0], d[0]);

      //  if (
      //    date.getFullYear() == y[0] &&
      //    date.getMonth() == m[0] &&
      //    date.getDate() == d[0]
      //  ) {
      //    const dt =
      //      y[0] +
      //      "-" +
      //      ("00" + Number(Number(m[0]) + 1)).slice(-2) +
      //      "-" +
      //      d[0];
      //    const dtname = dt + " (" + dayofweek[date.getDay()] + ")";
      //    this.days.push({
      //      code: dt,
      //      name: dtname
      //    });
      //  } else if (
      //    date.getFullYear() + 1 == y[0] &&
      //    date.getMonth() == 11 &&
      //    m[0] == "-1" &&
      //    date.getDate() == d[0]
      //  ) {
      //    const dt = date.getFullYear() + "-" + "12" + "-" + d[0];
      //    const dtname = dt + " (" + dayofweek[date.getDay()] + ")";
      //    this.days.push({
      //     code: dt,
      //      name: dtname
      //    });
      //  }

      //  if (
      //    date.getMonth() == 2 ||
      //    date.getMonth() == 4 ||
      //    date.getMonth() == 7 ||
      //    date.getMonth() == 9
      //  ) {
      //    if (date.getDate() == 30) {
      //      //31日を追加する
      //      const dt =
      //        date.getFullYear() +
      //        "-" +
      //        ("00" + (date.getMonth() + 1)).slice(-2) +
      //        "-31";
      //      const date2 = new Date(y[0], m[0], 31);
      //      const dtname = dt + " (" + dayofweek[date2.getDay()] + ")";
      //      this.days.push({
      //        code: dt,
      //        name: dtname
      //      });
      //    }
      //  }

      //  targetFrom.setDate(targetFrom.getDate() + 1);
      //}
    },
    async getUsers(userincode) {
      console.log("getUsers");
      try {
        const result = await this.$get(this.Paths.shainFilter);

        this.selectShozoku = this.shozokus.filter(e => e.code === this.shozoku);
        if (result) {
          this.users.length = 0;
          this.users = result;
          this.user = userincode ? userincode : result[0].code;
        }
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      }
    },
    async getShozoku(shozokuincode) {
      console.log("getShozoku");
      try {
        const result = await this.$get(this.Paths.shozokuFilterAssist);
        if (result) {
          this.shozokus.length = 0;
          this.shozokus = result;
          this.shozoku = shozokuincode ? shozokuincode : result[0].code;
        }
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      }
    }
  },
  async created() {
    console.log("created", this.args);
    this.displayType = this.args.displayType;
    const userincode = this.args.userincode;
    const shozokuincode = this.args.shozokuincode;
    this.dataheaders = this.args.dataheaders;
    this.databodies = this.args.databodies;
    const fromdate = this.args.fromdate;
    const todate = this.args.todate;

    await this.setDays(fromdate, todate);
    await this.getUsers(userincode);
    await this.getShozoku(shozokuincode);
  },
  mounted() {
    console.log("mounted", this.args);
  }
};
</script>
<style scoped>
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

table th {
  text-align: start !important;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  width: 5%;
  padding: 10px 5px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
}

table td {
  text-align: start !important;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  border-top: none;
  width: 5%;
  padding: 10px 5px;
  font-size: 0.875rem;
}

::v-deep .editable-control input[readonly="readonly"] {
  background-color: lightgray;
  cursor: not-allowed;
}
</style>
