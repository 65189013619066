
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "BeforeAfterList",
  props: {
    beforeHeaders: {
      type: Array as PropType<{ text: string; value: string }[]>,
      required: true
    },
    afterHeaders: {
      type: Array as PropType<{ text: string; value: string }[]>,
      required: true
    },
    beforeItem: {
      type: Object as PropType<any>,
      required: true
    },
    afterItem: {
      type: Object as PropType<any>,
      required: true
    },
    beforeTitle: {
      type: String,
      required: true
    },
    afterTitle: {
      type: String,
      required: true
    },
    dense: {
      type: Boolean,
      required: false,
      default: false
    }
  }
});
