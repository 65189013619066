<template>
  <DialogBase
    visible
    ref="dialog"
    :title="Title"
    icon="mdi-monitor-edit"
    midium
    complete
    close
    @clickComplete="complete(true)"
    @clickClose="close(false)"
  >
    <v-form v-model="valid" ref="form">
      <!-- {{ edit }} -->
      <v-row>
        <v-col>
          <v-text-field
            v-model.number="summary.worktime"
            label="勤務時間"
            type="number"
            prepend-inner-icon="mdi-account-clock"
            :suffix="getWorkTimeSuffix()"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="summary.workdays"
            label="勤務日数"
            type="number"
            prepend-inner-icon="mdi-calendar-account-outline"
            suffix="日"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="summary.salary1"
            label="基本給"
            type="number"
            prepend-inner-icon="mdi-wallet"
            suffix="円"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="summary.inovertime"
            label="時間内残業"
            type="number"
            prepend-inner-icon="mdi-clock-time-one-outline"
            suffix="時間"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="summary.inoverallowance"
            label="時間内手当"
            type="number"
            prepend-inner-icon="mdi-wallet"
            suffix="円"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model.number="summary.overtime"
            label="時間外残業"
            type="number"
            prepend-inner-icon="mdi-clock-time-one-outline"
            suffix="時間"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="summary.overallowance"
            label="時間外手当"
            type="number"
            prepend-inner-icon="mdi-wallet"
            suffix="円"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="summary.midnighttime"
            label="深夜時間"
            type="number"
            prepend-inner-icon="mdi-clock-time-one-outline"
            suffix="時間"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="summary.midnightallowance"
            label="深夜割増"
            type="number"
            prepend-inner-icon="mdi-wallet"
            suffix="円"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="summary.midnightovertime"
            label="深夜残業時間"
            type="number"
            prepend-inner-icon="mdi-clock-time-one-outline"
            suffix="時間"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model.number="summary.midnightoverallowance"
            label="深夜残業手当"
            type="number"
            prepend-inner-icon="mdi-wallet"
            suffix="円"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="summary.holidaytime"
            label="休日残業"
            type="number"
            prepend-inner-icon="mdi-clock-time-one-outline"
            suffix="時間"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="summary.holidayallowance"
            label="休日手当"
            type="number"
            prepend-inner-icon="mdi-wallet"
            suffix="円"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="summary.paiddays"
            label="有給日数"
            type="number"
            prepend-inner-icon="mdi-calendar-account-outline"
            suffix="日"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="summary.lateLeaveTime"
            label="遅早時間"
            type="number"
            prepend-inner-icon="mdi-clock-time-one-outline"
            suffix="時間"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model.number="summary.absencecount"
            label="欠勤日数"
            type="number"
            prepend-inner-icon="mdi-clock-time-one-outline"
            suffix="日"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="summary.absencetime"
            label="欠勤時間"
            type="number"
            prepend-inner-icon="mdi-clock-time-one-outline"
            suffix="時間"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="summary.commutingworkcount"
            label="通勤日数"
            type="number"
            prepend-inner-icon="mdi-calendar-account-outline"
            suffix="日"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="summary.commutingworkallowance"
            label="通勤日額"
            type="number"
            prepend-inner-icon="mdi-wallet"
            suffix="円"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="summary.specialvacationcount"
            label="特休回数"
            type="number"
            prepend-inner-icon="mdi-counter"
            suffix="回"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model.number="summary.assistallowance"
            label="応援手当"
            type="number"
            prepend-inner-icon="mdi-wallet"
            suffix="円"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="summary.otherallowance1"
            label="その他手当"
            type="number"
            prepend-inner-icon="mdi-wallet"
            suffix="円"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col> </v-col>
        <v-col> </v-col>
        <v-col> </v-col>
      </v-row>
    </v-form>
  </DialogBase>
</template>
<script>
// mixins
import Common from "@/mixins/common";
import Dialog from "@/mixins/dialog";
// VueGoodTable
import "vue-good-table/dist/vue-good-table.css";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import SalaryCalc from "@/models/SalaryCalc";

export default {
  name: "AttendanceSummaryFixDialog",
  mixins: [Common, Dialog, Forms, Api, ShowDialogs],
  data: () => ({
    targetmonth: "",
    user: {
      incode: "",
      name: ""
    },
    summary: {
      worktime: 0,
      workdays: 0,
      salary1: 0,
      salary2: 0,
      salary3: 0,
      salary4: 0,
      overtime: 0,
      inovertime: 0,
      inoverallowance: 0,
      overallowance: 0,
      midnighttime: 0,
      midnightallowance: 0,
      midnightovertime: 0,
      midnightoverallowance: 0,
      holidaytime: 0,
      holidayallowance: 0,
      paiddays: 0,
      paidallowance: 0,
      lateLeaveTime: 0,
      latecount: 0,
      earlycount: 0,
      absencecount: 0,
      absencetime: 0,
      otherallowance1: 0,
      otherallowance2: 0,
      nightallowance: 0,
      perfectallowance: 0,
      yearendallowance: 0,
      commutingdistance: 0,
      commutingallowance: 0,
      mealbill: 0,
      commutingworkcount: 0,
      commutingworkallowance: 0,
      parkingdailyallowance: 0,
      nightguardcount: 0,
      nightguardallowance: 0,
      emergencycount: 0,
      emergencyallowance: 0,
      midnighttelcount: 0,
      midnighttelallowance: 0,
      specialvacationcount: 0,
      specialvacationallowance: 0,
      welfarekaigo: 0,
      welfaresyogai: 0,
      minDispFlag: 0,
      nightcount: 0,
      assistallowance: 0
    },
    edit: false
  }),
  computed: {
    Title() {
      const s = this.targetmonth.split("-");
      const title = s[0] + "年" + s[1] + "月 " + this.user.name + " 合計修正";
      return title;
    }
  },
  methods: {
    close(result) {
      this.resolve(result);
      this.$close();
    },
    getWorkTimeSuffix() {
      const suffix = this.summary.minDispFlag == 0 ? "時間" : "分";
      return suffix;
    },
    async complete() {
      console.log("complete");

      try {
        const payload = new SalaryCalc(
          this.getPaymentDate(), // 日付
          this.user.incode, // 社員
          "-999", // 所属
          String(this.summary.worktime),
          String(this.summary.workdays),
          String(this.summary.salary1),
          String(this.summary.salary2), // 給与2
          String(this.summary.salary3), // 給与3
          String(this.summary.salary4), // 給与4
          String(this.summary.inovertime), // 所定内残業
          String(this.summary.overtime),
          String(this.summary.midnighttime),
          String(this.summary.midnightovertime),
          String(this.summary.holidaytime),
          String(this.summary.paiddays),
          String(this.summary.lateLeaveTime), //遅早時間
          String(this.summary.latecount),
          String(this.summary.earlycount),
          String(this.summary.absencetime),
          String(this.summary.nightcount), // 夜勤
          String(this.summary.commutingallowance),
          String(this.summary.commutingdistance),
          String(this.summary.holidayallowance),
          String(this.summary.paidallowance),
          String(this.summary.perfectallowance), // 皆勤手当
          String(this.summary.inoverallowance),
          String(this.summary.overallowance),
          String(this.summary.midnightallowance), // 夜勤手当
          String(this.summary.midnightoverallowance),
          String(this.summary.nightallowance),
          String(this.summary.otherallowance),
          String(this.summary.otherallowance1),
          String(this.summary.otherallowance2),
          String(this.summary.yearendallowance), // 年末年始手当
          String(this.summary.mealbill),
          String(this.summary.commutingworkcount),
          String(this.summary.commutingworkallowance),
          String(this.summary.parkingdailyallowance),
          String(this.summary.nightguardcount),
          String(this.summary.nightguardallowance),
          String(this.summary.emergencycount),
          String(this.summary.emergencyallowance),
          String(this.summary.midnighttelcount),
          String(this.summary.midnighttelallowance),
          String(this.summary.specialvacationcount),
          String(this.summary.specialvacationallowance),
          String(this.summary.welfarekaigo),
          String(this.summary.welfaresyogai),
          String(this.summary.minDispFlag),
          String(this.summary.assistallowance),
          String(this.summary.absencecount)
        );
        console.log("salaryfix", payload);
        if (this.edit) {
          await this.$put(this.Paths.attendanceSalaryFix, payload);
        } else {
          await this.$post(this.Paths.attendanceSalaryFix, payload);
        }

        this.$info("更新しました。");
        this.$close(true);
      } catch (e) {
        console.log("posterror", e);
        this.$error(e.message);
      }
    },
    getPaymentDate() {
      const ym = this.targetmonth.split("-");
      const dt = new Date(Number(ym[0]), Number(ym[1]), 1);
      dt.setDate(dt.getDate() - 1);

      const result =
        dt.getFullYear() +
        "-" +
        ("000000" + Number(dt.getMonth() + 1)).slice(-2) +
        "-" +
        ("000000" + dt.getDate()).slice(-2);

      return result;
    }
  },
  created() {
    console.log("created", this.args);
    this.targetmonth = this.args.targetmonth;
    this.user.incode = this.args.user.code;
    this.user.name = this.args.user.name;
    this.edit = this.args.edit;
    if (this.edit) this.summary = this.args.summary;
  },
  mounted() {
    console.log("mounted", this.args);
  }
};
</script>
