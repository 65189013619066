<template>
  <div>
    <h1>勤務表</h1>

    <v-radio-group
      v-model="select"
      row
      label="取得種別:"
      @change="getData(false)"
    >
      <v-radio
        label="ユーザー別"
        prepend-icon="mdi-toggle-switch"
        :value="Selects.User"
      ></v-radio>
      <v-radio
        label="所属別"
        prepend-icon="mdi-toggle-switch"
        :value="Selects.Office"
      ></v-radio>
    </v-radio-group>

    <v-card class="pa-5" flat outlined>
      <v-autocomplete
        v-model="user"
        v-if="select == Selects.User"
        :items="users"
        outlined
        label="ユーザー選択"
        item-text="name"
        item-value="code"
        chips
        @change="getKkubun()"
      >
      </v-autocomplete>
      <v-autocomplete
        v-model="shozoku"
        v-if="select == Selects.Office"
        :items="shozokus"
        outlined
        label="所属選択"
        item-text="name"
        item-value="code"
        chips
      >
      </v-autocomplete>
      <DatePicker
        v-model="date"
        label="対象年月"
        v-if="select == Selects.User"
        prepend-inner-icon="mdi-calendar"
        type="month"
        outlined
        next
        previous
      />
      <v-row v-if="select == Selects.Office">
        <v-col cols="5">
          <DatePicker
            v-model="dateFrom"
            label="対象年月From"
            prepend-inner-icon="mdi-calendar"
            type="day"
            v-if="select == Selects.Office"
            outlined
          />
        </v-col>
        <v-col cols="2"><p class="range-text">〜</p></v-col>
        <v-col cols="5">
          <DatePicker
            v-model="dateTo"
            label="対象年月To"
            prepend-inner-icon="mdi-calendar"
            type="day"
            v-if="select == Selects.Office"
            outlined
          />
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <Btn color="info" icon="file-import" @click="getData(true)">取得</Btn>
      </v-card-actions>
    </v-card>
    <v-card class="mt-5" v-if="!$isEmpty(cols)" flat outlined>
      <v-card-title>
        <v-row>
          <v-col>
            <h3 class="headline">勤務実績</h3>
          </v-col>
          <v-spacer />
          <!-- <v-radio-group
            v-model="tableFilter"
            class="table-filter"
            row
            label="フィルタ:"
            @change="changeTableFilter(tableFilter)"
          >
            <v-radio
              label="全て"
              prepend-icon="mdi-toggle-switch"
              :value="Filters.All"
            ></v-radio>
            <v-radio
              label="実績"
              prepend-icon="mdi-toggle-switch"
              :value="Filters.Attendance"
            ></v-radio>
            <v-radio
              label="応援"
              prepend-icon="mdi-toggle-switch"
              :value="Filters.Assist"
            ></v-radio>
            <v-radio
              label="その他"
              prepend-icon="mdi-toggle-switch"
              :value="Filters.Other"
            ></v-radio>
          </v-radio-group> -->
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row justify="start">
          <v-col>
            <Btn
              color="success"
              icon="plus"
              text
              outlined
              :disabled="isShowCalc()"
              @click="add"
            >
              追加
            </Btn>
          </v-col>
          <v-col>
            <Btn
              color="error"
              icon="delete"
              text
              outlined
              @click="remove"
              :disabled="!selected.length || isShowCalc()"
            >
              削除
            </Btn>
          </v-col>
          <!-- <v-col>
            <Btn
              color="success"
              icon="mdi-plus-box-multiple-outline"
              text
              outlined
              :disabled="isShowCalc()"
              @click="bulkadd"
            >
              一括追加
            </Btn>
          </v-col>
          <v-col>
            <Btn
              color="success"
              icon="mdi-pencil-box-multiple-outline"
              text
              outlined
              :disabled="!selected.length || isShowCalc()"
              @click="bulkfix"
            >
              一括更新
            </Btn>
          </v-col> -->
          <v-col>
            <Btn
              color="success"
              icon="mdi-plus-box-multiple-outline"
              text
              outlined
              :disabled="isShowCalc()"
              @click="assistadd"
            >
              応援追加
            </Btn>
          </v-col>
          <!-- <v-col>
            <Btn
              color="success"
              icon="mdi-plus-box-multiple-outline"
              text
              outlined
              :disabled="isShowCalc()"
              @click="otheradd"
            >
              その他追加
            </Btn>
          </v-col> -->
          <!-- <v-col>
            <Btn
              color="info"
              icon="mdi-arrow-bottom-right-thick"
              text
              :disabled="isShowCalc()"
              outlined
              @click="createByShift"
            >
              シフトから実績を追加
            </Btn>
          </v-col> -->
          <!-- <v-col>
            <Btn
              icon="mdi-monitor-edit"
              color="primary"
              text
              outlined
              v-show="select == Selects.User"
              :disabled="!$isEmpty(summary)"
              @click="summaryfix(false)"
            >
              給与合計追加
            </Btn>
          </v-col> -->
        </v-row>
      </v-card-text>

      <v-toolbar class="mb-2" flat v-show="select == Selects.User">
        <!-- <v-text-field v-model="sumkbn" readonly class="mx-5"></v-text-field> -->
        <v-spacer></v-spacer>
        <Btn
          icon="mdi-arrow-left-bold"
          color="#ff6666"
          v-show="backForCalc"
          text
          outlined
          @click="backCalc"
          class="mx-5"
        >
          月集計状況に戻る
        </Btn>
        <Btn
          icon="mdi-arrow-left-bold"
          color="#ff6666"
          v-show="backForShift"
          text
          outlined
          @click="backShift"
          class="mx-5"
        >
          シフト表に戻る
        </Btn>
        <Btn
          icon="mdi-arrow-left-bold"
          color="#ff6666"
          v-show="backForApprovalStatus"
          text
          outlined
          @click="backApprovalStatus"
          class="mx-5"
        >
          承認処理に戻る
        </Btn>
        <Btn
          icon="calculator"
          color="primary"
          v-show="select == Selects.User"
          text
          outlined
          :disabled="isShowCalc()"
          @click="calctime"
          class="mx-5"
        >
          時間計算
        </Btn>
        <Btn
          icon="calculator"
          color="primary"
          v-show="select == Selects.User"
          text
          outlined
          :disabled="isShowCalc()"
          @click="calc"
        >
          月集計
        </Btn>
      </v-toolbar>

      <v-divider></v-divider>

      <v-card-text>
        <vue-good-table
          ref="table"
          class="detail-table"
          :columns="cols"
          :rows="rows"
          :row-style-class="rowStyleClass"
          :select-options="options.select"
          styleClass="vgt-table striped bordered condensed"
          @on-selected-rows-change="onChange"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'remarks'">
              <v-chip
                class="mx-1"
                small
                v-for="remark in props.row.remarks"
                :key="remark"
              >
                {{ remark }}
              </v-chip>
            </span>
            <!-- Actions -->
            <span v-else-if="props.column.field === 'actions'">
              <Btn color="info" text small block @click="fix(props.row)">
                修正
              </Btn>
            </span>
            <!-- Approval -->
            <span v-else-if="props.column.field === 'approval'">
              <Btn
                v-if="props.row.petitionflag == '1'"
                color="info"
                text
                small
                block
                @click="onShiftDialog(props.row.shiftid)"
              >
                確認
              </Btn>
            </span>
          </template>
        </vue-good-table>

        <ShiftDialog v-model="isShiftDialog" :shift-id="selectShiftId" />

        <v-text-field
          v-if="select == Selects.User && summary"
          v-model="usernm"
          readonly
        >
        </v-text-field>
        <table
          v-if="select == Selects.User && summary"
          class="mt-5 detail-table"
        >
          <caption class="headline">
            合計
          </caption>
          <tr class="detail-table-header">
            <th v-for="(header, i) in summaryheader" :key="i">
              {{ header.text }}
            </th>
          </tr>
          <tr v-for="(sum, i) in summary" :key="i">
            <td
              v-for="(header, tdIndex) in summaryheader"
              class="summary-td"
              :key="tdIndex"
              :style="
                summary.length - 1 === i
                  ? monthWorkHourOverAssistTime(header.value, sum)
                  : {}
              "
            >
              {{ editComma(header.value, sum[header.value]) }}
            </td>
            <!--td v-for="(s, key) in sum" :key="key" :class="isDispSummary(key)">
              {{ editComma(key, s) }}
            </td-->
          </tr>
        </table>

        <v-row
          class="mt-5 d-flex justify-content-end"
          v-if="!$isEmpty(summary)"
          flat
          outlined
        >
          <Btn
            v-if="isShowApproval()"
            icon="mdi-arrow-left-bold-hexagon-outline"
            color="#003399"
            class="mx-5 mb-5"
            text
            outlined
            :disabled="isDisabledApprovalUser()"
            @click="moveNextUser(false)"
            >前の承認者へ</Btn
          >
          <Btn
            v-if="isShowApproval()"
            icon="mdi-arrow-right-bold-hexagon-outline"
            color="#003399"
            class="mx-5 mb-5"
            text
            outlined
            :disabled="isDisabledApprovalUser()"
            @click="moveNextUser(true)"
            >次の承認者へ</Btn
          >
          <Btn
            v-if="showConfirm"
            icon="mdi-arrow-left-bold-hexagon-outline"
            color="#003399"
            class="mx-5 mb-5"
            text
            outlined
            @click="moveNextemployee(false)"
            >前の確定者へ</Btn
          >
          <Btn
            v-if="showConfirm"
            icon="mdi-arrow-right-bold-hexagon-outline"
            color="#003399"
            class="mx-5 mb-5"
            text
            outlined
            @click="moveNextemployee(true)"
            >次の確定者へ</Btn
          >
          <v-spacer></v-spacer>
          <!-- <Btn
            icon="mdi-monitor-edit"
            color="primary"
            text
            outlined
            v-if="isShowApproval() || showConfirm"
            @click="summaryfix(true)"
            class="mb-5"
            :disabled="isShowCalc()"
            >合計修正</Btn
          > -->
          <Btn
            v-if="isShowApproval() && !isShowResult()"
            @click="approval()"
            icon="mdi-monitor-lock"
            color="success"
            class="mx-5 mb-5"
            >承認</Btn
          >
          <v-alert
            v-if="isShowApproval() && isShowResult()"
            color="blue"
            dense
            type="success"
            >承認済</v-alert
          >

          <Btn
            v-if="showConfirm"
            icon="mdi-monitor-off"
            color="warning"
            text
            outlined
            class="mb-5, mx-5"
            :disabled="isDisabledConfirm('0')"
            @click="salaryConfirm('0')"
            >給与確定取消</Btn
          >
          <Btn
            v-if="showConfirm"
            icon="mdi-monitor-lock"
            color="success"
            text
            outlined
            class="mb-5"
            :disabled="isDisabledConfirm('1')"
            @click="salaryConfirm('1')"
            >給与確定</Btn
          >
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// mixins
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import moment from "moment";
// VueGoodTable
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";

import Api from "@/mixins/api";
import * as Dummy from "./dummy";
import AttendanceFixDialogVue from "./dialogs/AttendanceFixDialog.vue";
import AttendanceDetail from "@/models/AttendanceDetail";
import AttendanceSummaryFixDialog from "./dialogs/AttendanceSummaryFixDialog.vue";
import AttendanceBulkAddDialog from "./dialogs/AttendanceBulkAddDialog.vue";
import AttendanceBulkFixDialog from "./dialogs/AttendanceBulkFixDialog.vue";
import AttendanceOfficeFixDialog from "./dialogs/AttendanceOfficeFixDialog.vue";
import AttendanceBulkOfficeAddDialog from "./dialogs/AttendanceBulkOfficeAddDialog.vue";
import AttendanceAssistDialog from "@/views/dialogs/AttendanceAssistDialog";
//import AttendanceOtherDialog from "@/views/dialogs/AttendanceOtherDialog";
import ShiftDialog from "@/components/approval/shift/ShiftDialog.vue";

const Selects = {
  User: 0,
  Office: 1,
  Me: 2
};
const Filters = {
  All: 0, // 全て
  Attendance: 1, // 通常勤務実績
  Assist: 2, // 応援実績
  Other: 3 //その他
};

const DATE = new Date();
const DATENOW =
  DATE.getFullYear() +
  "-" +
  ("00" + Number(DATE.getMonth() + 1)).slice(-2) +
  "-" +
  ("00" + Number(DATE.getDate())).slice(-2);
const Users = [];

const regex = /[^\s]+/;

const filterFn = (data, filterString) => {
  console.warn(data, filterString);
  if (!filterString) return false;

  if (filterString instanceof RegExp) {
    return filterString.test(data);
  } else {
    return filterString === data;
  }
};

const filterOptions = {
  styleClass: "class1",
  enabled: true,
  placeholder: "",
  filterValue: "",
  filterDropdownItems: [],
  filterFn: filterFn
};
export default {
  name: "AttendanceDetails",
  mixins: [Common, ShowDialogs, Api],
  components: {
    VueGoodTable,
    ShiftDialog
  },
  data: () => ({
    menu: false,
    date:
      DATE.getFullYear() +
      "-" +
      ("00" + Number(DATE.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + Number(DATE.getDate())).slice(-2),
    dateFrom: DATENOW,
    dateTo: DATENOW,
    select: Selects.User,
    tableFilter: Filters.All,
    records: [],
    isConfirm: false,
    attendances: { rows: [], columns: [] },
    summaries: { rows: [], columns: [] },
    total: { rows: [], columns: [] },
    cols: [],
    rows: [],
    summaryheader: [],
    summary: [],
    monthWorkHour: 0,
    users: Users,
    user: Users[0],
    Selects,
    Filters,
    options: {
      select: {
        enabled: true,
        selectOnCheckboxOnly: true,
        disableSelectInfo: true
      },
      search: { enabled: true }
    },
    selected: [],
    shozokus: [],
    shozoku: { code: "", name: "" },
    backForCalc: false,
    backForShift: false,
    backForApprovalStatus: false,
    showConfirm: false,
    usernm: "",
    duplicationflag: false,
    kkubun: 1,
    sumkbn: "",
    isShiftDialog: false,
    selectShiftId: ""
  }),
  computed: {
    SummaryHeader() {
      return null;
    },
    Year() {
      return !this.date ? undefined : parseInt(this.date.split("-")[0]);
    },
    Month() {
      return !this.date ? undefined : parseInt(this.date.split("-")[1]);
    },
    Day() {
      if (!this.date) return undefined;
      const split = this.date.split("-");

      return split.length < 2 ? undefined : parseInt(split[2]);
    }
  },
  methods: {
    onShiftDialog(shiftId) {
      this.selectShiftId = shiftId;
      this.isShiftDialog = true;
    },
    rowStyleClass(row) {
      console.log("rowStyleClass", row);
      // return row.remarks === "1" ? "yellow" : "";
      let myclass = "";
      if (row.assistFlag === "1") {
        myclass = "light-blue lighten-5";
      } else if (row.otherFlag === "1") {
        myclass = "purple lighten-4";
      } else if (row.officialholiday == "1" || row.officialholiday == "2") {
        myclass = "orange lighten-3";
      } else if (row.remarks === "1") {
        if (row.reserveid2 === "1") {
          myclass = "light-green lighten-3";
        } else {
          myclass = "yellow";
        }
      } else if (row.remarks === "3") {
        myclass = "red lighten-3";
      } else if (row.remarks === "4") {
        myclass = "red lighten-4";
      } else if (row.remarks === "5") {
        myclass = "red lighten-5";
      } else if (row.remarks === "51") {
        myclass = "lime lighten-2";
      } else if (row.remarks === "8") {
        myclass = "grey lighten-1";
      } else if (row.remarks === "9") {
        myclass = "pink lighten-4";
      }
      return myclass;
    },
    async getTargetMonth() {
      const startmonth = new Date(this.Year, this.Month, 16);
      let targetdt = new Date(this.Year, this.Month, this.Day);

      const m = moment(targetdt);
      if (targetdt == startmonth) {
        // targetdt.setMonth(targetdt.getMonth() + 1);
        targetdt = m.add(1, "M").toDate();
      } else if (targetdt > startmonth && targetdt.getDate() < 16) {
        //targetdt.setMonth(targetdt.getMonth() - 1);
        targetdt = m.add(-1, "M").toDate();
      } else if (targetdt < startmonth && targetdt.getDate() < 16) {
        // targetdt.setMonth(targetdt.getMonth() - 1);
        targetdt = m.add(-1, "M").toDate();
      }

      let targetMonth =
        targetdt.getFullYear() +
        "-" +
        ("00" + Number(targetdt.getMonth())).slice(-2);

      if (Number(targetdt.getMonth()) == 0) {
        const m2 = moment(targetdt);
        targetdt = m2.add(-1, "M").toDate();
        targetMonth =
          targetdt.getFullYear() +
          "-" +
          ("00" + Number(targetdt.getMonth() + 1)).slice(-2);
      }

      return targetMonth;
    },
    async confirm() {
      this.isConfirm = await this.$confirm(
        "勤務表を提出します。\nよろしいですか？"
      );
    },
    async getKkubun() {
      const param = {
        incode: this.user.code ? this.user.code : this.user
      };
      console.log("getKkubun", param);
      const encode = encodeURI(JSON.stringify(param));
      const kkubun = await this.$get(this.Paths.shainKkubun, "query=" + encode);
      this.kkubun = kkubun;
      return kkubun;
    },
    async getData(api) {
      let data;
      this.duplicationflag = false;
      switch (this.select) {
        case Selects.Me:
        case Selects.User:
        default: {
          if (api) {
            this.$loading();

            try {
              data = await this.getRecordByUser();
              // TODO 暫定対応:シフトあり、実績なしのデータを非表示
              const bodies = [];
              data.body.forEach(e => {
                if (e.recordid !== null) bodies.push(e);
              });
              data.body = bodies;
              console.log("this.user", this.user);
              const selectuser = this.users.filter(e => e.code === this.user);
              console.log("selectuser", selectuser);
              this.usernm = selectuser[0].name;
              this.sumkbn = "変形労働制：" + selectuser[0].option1;
            } catch (e) {
              console.log(e);
              this.$error(e.message);
            } finally {
              this.$unloading();
            }
          } else {
            data = Dummy.getPrivateRostar();
          }
          break;
        }
        case Selects.Office:
          if (api) {
            this.$loading();
            try {
              data = await this.getRecordByOffice();
            } catch (e) {
              console.log(e);
              this.$error(e.message);
            } finally {
              this.$unloading();
            }
          } else {
            data = Dummy.getOfficeRostar();
          }
          break;
      }
      await this.setAttendanceDetails(data);
    },
    async getRecordByUser() {
      const targetMonth = await this.getTargetMonth();
      const param = {
        incode: this.user.code ? this.user.code : this.user,
        targetmonth: targetMonth
      };
      console.log("getRecordByUser", param);
      const encode = encodeURI(JSON.stringify(param));
      return await this.$get(this.Paths.attendance, "query=" + encode);
    },
    async getRecordByOffice() {
      const param = {
        affiliationId: this.shozoku.code ? this.shozoku.code : this.shozoku,
        attendanceDateFrom: this.dateFrom,
        attendanceDateTo: this.dateTo
      };
      console.log("getRecordByOffice", param);
      const encode = encodeURI(JSON.stringify(param));
      return await this.$get(this.Paths.attendance, "query-office=" + encode);
    },
    async fix(item) {
      console.log("fix", item);

      if (item.officialholiday == "1") {
        this.$error("公休日のレコードは、編集できません。");
        return;
      }

      if ((await this.isShowCalc()) == true) {
        this.$error(
          "既に上長承認が行われているか、 \n 給与確定済のため、編集できません。"
        );
        return;
      }

      let result;
      if (this.select === this.Selects.User) {
        item.employee = this.user.code ? this.user.code : this.user;
        result = await this.$showDialog(AttendanceFixDialogVue, {
          args: item,
          kkubun: this.kkubun
        });
      } else {
        result = await this.$showDialog(AttendanceOfficeFixDialog, {
          args: item
        });
      }

      if (result) {
        await this.getData(true);
      }
    },
    onChange(e) {
      console.log(e);
      this.selected = e.selectedRows;
    },
    async add() {
      console.log("add", "enter add");
      const ts = DATE.getTime();

      let usercode = "";
      if (this.select === this.Selects.User) {
        usercode = this.user.code ? this.user.code : this.user;
      }

      let officecode = "";
      if (this.select === this.Selects.Office) {
        officecode = this.shozoku.code ? this.shozoku.code : this.shozoku;
      }

      const item = new AttendanceDetail({
        recordid: "",
        employee: usercode,
        affiliation: officecode,
        shiftid: "",
        shiftpatternid: "",
        datestr: DATE,
        date: ts,
        start: "",
        opeTime: "",
        end: "",
        distance: "0",
        inover: "",
        lateleave: "",
        amount: "",
        breakflg: "",
        daycount: "",
        nightcount: "",
        hadmeal: "0",
        careShu: "0",
        careKbn: "0",
        timeKbn: "0",
        assistFlag: "0", // 応援
        otherFlag: "0"
      });

      let result;
      if (this.select === this.Selects.User) {
        result = await this.$showDialog(AttendanceFixDialogVue, {
          args: item,
          kkubun: this.kkubun
        });
      } else {
        result = await this.$showDialog(AttendanceOfficeFixDialog, {
          args: item
        });
      }

      if (result) {
        await this.getData(true);
      }
    },
    async edit() {
      console.log("edit");

      const selected = this.selected;

      const result = await this.$AttendanceFix(selected);
      if (result) {
        selected.forEach(select => {
          Object.keys(result).forEach(key => {
            if (result[key]) select[key] = result[key];
          });
        });
      }
    },
    async remove() {
      console.log("remove");
      const message = [];
      /*if (this.select == this.Selects.Office && authority == "1") {
        message[0] =
          this.selected.length +
          "件 削除します。\nよろしいですか？ \n 上長承認がされている場合は、削除されません。";
      } else {
        message[0] = this.selected.length + "件 削除します。\nよろしいですか？";
      }*/
      message[0] =
        this.selected.length +
        "件 削除します。 \nよろしいですか？ \n※以下の場合は、削除されませんのでご注意ください。\n公休日、上長承認済、給与確定済";
      const result = await this.$deleteConfirm(message[0]);

      if (result) {
        const ids = [];
        this.selected.forEach(s => {
          if (s.officialholiday != "1" && s.officialholiday != "2") {
            ids.push(s.recordid);
          }
        });

        if (ids.length == 0) {
          this.$info("削除対象データが有りませんでした。", "勤務実績");
          return;
        }

        this.$loading();

        try {
          const param = ids.join(",");
          const encode = encodeURI(param);
          await this.$del(this.Paths.attendance, "ids=" + encode);

          console.log(this.rows);
          const rows = this.rows;
          const indexes = this.selected.map(select => select.originalIndex);
          this.rows = rows.filter((row, i) => !indexes.includes(i));

          await this.$info("更新しました。", "勤務実績");
        } catch (e) {
          console.log(e);
          this.$error(e.message);
        } finally {
          this.$unloading();
          await this.getData(true);
        }
      }
    },
    async bulkadd() {
      console.log("bulkadd");

      const targetMonth = await this.getTargetMonth();

      const targetFromDate = new Date(
        parseInt(targetMonth.split("-")[0]),
        parseInt(targetMonth.split("-")[1]),
        16
      );

      const targetToDate = new Date(
        targetFromDate.getFullYear(),
        targetFromDate.getMonth() + 1,
        15
      );

      let result = false;
      if (this.select === this.Selects.User) {
        const incode = this.user.code ? this.user.code : this.user;
        const user = this.users.filter(e => e.code === incode);

        const param = {
          incode: incode,
          username: user[0].name,
          fromdate: targetFromDate,
          todate: targetToDate
        };

        result = await this.$showDialog(AttendanceBulkAddDialog, {
          args: param
        });
      } else {
        const officecode = this.shozoku.code ? this.shozoku.code : this.shozoku;
        const shozoku = this.shozokus.filter(e => e.code === officecode);
        const param = {
          officecode: officecode,
          officename: shozoku[0].name,
          fromdate: targetFromDate,
          todate: targetToDate
        };
        result = await this.$showDialog(AttendanceBulkOfficeAddDialog, {
          args: param
        });
      }

      if (result) {
        await this.getData(true);
      }
    },
    async bulkfix() {
      const incode = this.user.code ? this.user.code : this.user;
      const user = this.users.filter(e => e.code === incode);

      const recordIds = [];
      this.selected.forEach(s => {
        if (s.officialholiday != "1") {
          recordIds.push(s.recordid);
        }
      });

      if (recordIds.length == 0) {
        await this.$info(
          "更新対象となるデータがありません。 \n ※公休日は、編集できません。"
        );
        return;
      }

      const param = {
        incode: incode,
        username: user[0].name,
        recordIds: recordIds
      };

      const result = await this.$showDialog(AttendanceBulkFixDialog, {
        args: param
      });

      if (result) {
        await this.getData(true);
      }
    },
    async assistadd() {
      console.log("assistadd");
      let result = false;
      let userincode = "";
      let shozokuincode = "";

      if (this.select === this.Selects.User) {
        userincode = this.user.code ? this.user.code : this.user;
      } else {
        shozokuincode = this.shozoku.code ? this.shozoku.code : this.shozoku;
      }

      const targetMonth = await this.getTargetMonth();
      const targetFromDate = new Date(
        parseInt(targetMonth.split("-")[0]),
        parseInt(targetMonth.split("-")[1]),
        16
      );
      const targetToDate = new Date(
        targetFromDate.getFullYear(),
        targetFromDate.getMonth() + 1,
        15
      );
      console.log("targetFromDate", targetFromDate);
      console.log("targetToDate", targetToDate);

      const queryparam = {
        requesttype: this.select === Selects.User ? "0" : "1",
        userincode: userincode,
        shozokuincode: shozokuincode,
        fromdate: await this.getTargetDateString(targetFromDate),
        todate: await this.getTargetDateString(targetToDate)
      };
      const encode = encodeURI(JSON.stringify(queryparam));
      const records = await this.$get(
        this.Paths.attendanceAssist,
        "query=" + encode
      );

      const param = {
        displayType: this.select,
        userincode: userincode,
        shozokuincode: shozokuincode,
        dataheaders: records.headers,
        databodies: records.body,
        fromdate: targetFromDate,
        todate: targetToDate
      };
      result = await this.$showDialog(AttendanceAssistDialog, {
        args: param
      });

      if (result) {
        await this.getData(true);
      }
    },
    /*async otheradd() {
      console.log("otheradd");
      let result = false;
      let userincode = "";
      let shozokuincode = "";

      if (this.select === this.Selects.User) {
        userincode = this.user.code ? this.user.code : this.user;
      } else {
        shozokuincode = this.shozoku.code ? this.shozoku.code : this.shozoku;
      }

      const targetMonth = await this.getTargetMonth();
      const targetFromDate = new Date(
        parseInt(targetMonth.split("-")[0]),
        parseInt(targetMonth.split("-")[1]),
        16
      );
      const targetToDate = new Date(
        targetFromDate.getFullYear(),
        targetFromDate.getMonth() + 1,
        15
      );
      console.log("targetFromDate", targetFromDate);
      console.log("targetToDate", targetToDate);

      const queryparam = {
        requesttype: this.select === Selects.User ? "0" : "1",
        userincode: userincode,
        shozokuincode: shozokuincode,
        fromdate: await this.getTargetDateString(targetFromDate),
        todate: await this.getTargetDateString(targetToDate)
      };
      const encode = encodeURI(JSON.stringify(queryparam));
      const records = await this.$get(
        this.Paths.attendanceOther,
        "query=" + encode
      );

      const param = {
        displayType: this.select,
        userincode: userincode,
        shozokuincode: shozokuincode,
        dataheaders: records.headers,
        databodies: records.body,
        fromdate: targetFromDate,
        todate: targetToDate
      };
      result = await this.$showDialog(AttendanceOtherDialog, {
        args: param
      });

      if (result) {
        await this.getData(true);
      }
    },*/
    async createByShift() {
      console.log("createByShift");
      let targetFrom, targetTo;

      if (this.select === Selects.Office) {
        targetFrom = new Date(
          this.dateFrom.split("-")[0],
          this.dateFrom.split("-")[1] - 1,
          this.dateFrom.split("-")[2]
        );

        targetTo = new Date(
          this.dateTo.split("-")[0],
          this.dateTo.split("-")[1] - 1,
          this.dateTo.split("-")[2]
        );

        if (targetFrom > targetTo) {
          this.$warning("開始日の指定が正しくありません");
          return;
        }
      }
      const confirm = await this.$confirm(
        "シフトから実績を作成します。\nよろしいですか？"
      );
      if (!confirm) return;
      if (this.select === Selects.Office) {
        console.log(targetTo, DATE);
        if (targetTo >= DATE) {
          const f = await this.$confirm(
            "終了日に本日以降の日付が設定されています。\nよろしいですか？"
          );
          if (!f) return;
        }
      }
      let param = {};
      if (this.select === Selects.User) {
        const targetMonth = await this.getTargetMonth();
        param = {
          requestType: "0",
          userIncode: this.user.code ? this.user.code : this.user,
          targetMonth: targetMonth
        };
      }
      if (this.select === Selects.Office) {
        param = {
          requestType: "1",
          shozokuIncode: this.shozoku.code ? this.shozoku.code : this.shozoku,
          targetDateFrom: this.dateFrom,
          targetDateTo: this.dateTo
        };
      }
      this.$loading();
      try {
        await this.$post(this.Paths.shiftAttendance, param);
        await this.$info("更新しました。");
        let data = [];
        if (this.select === Selects.User) {
          data = await this.getRecordByUser();
        }
        if (this.select === Selects.Office) {
          data = await this.getRecordByOffice();
        }
        await this.setAttendanceDetails(data);
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async calctime() {
      const result = await this.$confirm(
        "未計算の時間計算を実行します。\nよろしいですか？\n※残業申請がないものについては、残業なしで計算されます。\nただし、承認中の申請が残っている場合は計算されません。\n\nまた、計算済みの残業については再計算される可能性があります。"
      );
      if (!result) return;

      this.$loading();

      try {
        console.log("calctime");
        const data = await this.postForCalc(this.Paths.attendanceCalcTime);
        // TODO 暫定対応:シフトあり、実績なしのデータを非表示
        const bodies = [];
        data.body.forEach(e => {
          if (e.recordid !== null) bodies.push(e);
        });
        data.body = bodies;
        await this.setAttendanceDetails(data);
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async calc() {
      // データに不備がないかチェックをする
      const result2 = await this.postCalcCheck(this.Paths.attendanceCalcCheck);
      console.log("result2", result2);

      if (result2.okflag != null && result2.okflag == "1") {
        await this.$error(
          "打刻漏れまたは時間超過で時間計算がされていないか、\n承認が完了していない申請が残っています。"
        );
        return;
      } else if (result2.okflag != null && result2.okflag == "2") {
        const check = await this.$confirm(
          "公休日の出勤があるか、出勤予定日に打刻がありません。\n処理を続行しますか？"
        );
        if (!check) return;
      } else if (result2.okflag != null && result2.okflag == "3") {
        await this.$error(
          "傷病の休みの日に、実働時間が計算されています。\n出勤されている場合は、シフトを変更してください。\nそうでない場合は、削除または、開始と終了を「00:00」で登録してください。"
        );
        return;
      } else {
        const result = await this.$confirm(
          "月次集計を実行します。\nよろしいですか？"
        );
        if (!result) return;
      }

      this.$loading();
      try {
        console.log("calc");
        const data = await this.postForCalc(this.Paths.attendanceCalc);
        // TODO 暫定対応:シフトあり、実績なしのデータを非表示
        const bodies = [];
        data.body.forEach(e => {
          if (e.recordid !== null) bodies.push(e);
        });
        data.body = bodies;
        await this.setAttendanceDetails(data);
        this.showConfirm = false;
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async postCalcCheck(path) {
      console.log("postCalcCheck", path);

      const targetMonth = await this.getTargetMonth();

      const targetFromDate = new Date(
        parseInt(targetMonth.split("-")[0]),
        parseInt(targetMonth.split("-")[1]),
        16
      );

      const targetToDate = new Date(
        targetFromDate.getFullYear(),
        targetFromDate.getMonth() + 1,
        15
      );

      const param = {
        employeeId: this.user.code ? this.user.code : this.user,
        workdatefrom: await this.getTargetDateString(targetFromDate),
        workdateto: await this.getTargetDateString(targetToDate),
        targetmonth: targetMonth,
        searchkbn: "0"
      };

      return await this.$post(path, param);
    },
    async postForCalc(path) {
      console.log("postForCalc", path);

      const targetMonth = await this.getTargetMonth();

      const targetFromDate = new Date(
        parseInt(targetMonth.split("-")[0]),
        parseInt(targetMonth.split("-")[1]),
        16
      );

      const targetToDate = new Date(
        targetFromDate.getFullYear(),
        targetFromDate.getMonth() + 1,
        15
      );

      const param = {
        employeeId: this.user.code ? this.user.code : this.user,
        workdatefrom: await this.getTargetDateString(targetFromDate),
        workdateto: await this.getTargetDateString(targetToDate),
        targetmonth: targetMonth,
        searchkbn: "0"
      };

      return await this.$post(path, param);
    },
    async getUsers() {
      console.log("users");
      try {
        const result = await this.$get(this.Paths.shainApprovalFilter);
        if (result) {
          this.users.length = 0;
          this.users = result;
          this.user = result[0].code;
        }
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      }
    },
    async getShozoku() {
      console.log("getShozoku");
      try {
        const result = await this.$get(this.Paths.shozokuFilter);
        if (result) {
          this.shozokus.length = 0;
          this.shozokus = result;
          this.shozoku = result[0].code;
        }
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      }
    },
    async summaryfix(edit) {
      console.log("summaryfix");

      const params = await this.getSummaryFixParameters(edit);
      if (edit) params.summary = this.summary[this.summary.length - 1];

      const result = await this.$showDialog(AttendanceSummaryFixDialog, {
        args: params
      });

      if (result && !edit) {
        await this.getData(true);
      }
    },
    async getTargetDateString(targetDate) {
      console.log("getMonth", targetDate.getMonth());
      if (targetDate.getMonth() === 0) {
        return targetDate.getFullYear() - 1 + "-12-" + targetDate.getDate();
      } else {
        return (
          targetDate.getFullYear() +
          "-" +
          targetDate.getMonth() +
          "-" +
          targetDate.getDate()
        );
      }
    },
    async setAttendanceDetails(data) {
      const headers = data.headers.map(header => ({
        label: header.text,
        field: header.value,
        sortable: !["remarks"].includes(header.value),
        // フィルタ追加
        filterOptions: Object.assign({}, filterOptions)
      }));

      headers.push({ label: "操作", field: "actions", sortable: false });
      headers.push({ label: "承認", field: "approval", sortable: false });

      const date = headers.find(col => col.field === "date");
      if (date) {
        date.type = "date";
        date.dateInputFormat = "T"; //"yyyy-MM-dd"; // expects 2018-03-16
        date.dateOutputFormat = "M 月 dd 日"; // outputs Mar 16th 2018
      }
      const bodies = [];
      data.body.forEach(e => {
        if (e.recordid !== null) bodies.push(e);
      });
      data.body = bodies;
      this.cols = headers;
      this.rows = data.body;
      this.summaryheader = data.summaryheadders;
      this.summary = data.summary;
      this.monthWorkHour = data.monthWorkHour;
      this.changeTableFilter(this.tableFilter); // フィルタ処理
    },
    async salaryConfirm(confirmFlag) {
      this.$loading();
      try {
        console.log("salaryConfirm", confirmFlag);

        const dt = new Date(
          parseInt(this.date.split("-")[0]),
          parseInt(this.date.split("-")[1]),
          1
        );
        dt.setDate(dt.getDate() - 1);
        const paymentdate =
          dt.getFullYear() +
          "-" +
          ("00" + Number(dt.getMonth() + 1)).slice(-2) +
          "-" +
          ("00" + dt.getDate()).slice(-2);

        const param = {
          paymentdate: paymentdate,
          employeeId: this.user.code ? this.user.code : this.user,
          isConfirm: confirmFlag
        };

        await this.$put(this.Paths.attendanceSalaryConfirm, param);
        this.summary.filter(e =>
          e.affiliationName === "合計" ? (e.isconfirm = confirmFlag) : false
        );

        await this.$info("更新しました。");

        const data = await this.getRecordByUser();
        await this.setAttendanceDetails(data);
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    monthWorkHourOverAssistTime(property, sum) {
      if (property === "assisttime") {
        if (this.monthWorkHour < sum[property]) {
          return {
            color: "white",
            backgroundColor: "red"
          };
        }
      }
      return {};
    },
    editComma(key, val) {
      if (key === "affiliationName") return val;
      return Number(val).toLocaleString(undefined, {
        maximumFractionDigits: 20
      });
    },
    isDispSummary(key) {
      if (
        key === "minDispFlag" ||
        key === "isconfirm" ||
        key === "targetUserFlg" ||
        key === "approvalStatus" ||
        key === "appoverflag" ||
        key === "monthhourflag" ||
        key === "salary2" ||
        key === "salary3" ||
        key === "salary4" ||
        key === "paidallowance" ||
        key === "parkingdailyallowance" ||
        key === "otherallowance1" ||
        key === "otherallowance2" ||
        key === "nightallowance" ||
        key === "perfectallowance" ||
        key === "yearendallowance" ||
        key === "commutingdistance" ||
        key === "commutingallowance" ||
        key === "mealbill" ||
        key === "nightcount" ||
        key === "nightguardcount" ||
        key === "nightguardallowance" ||
        key === "emergencycount" ||
        key === "emergencyallowance" ||
        key === "midnighttelcount" ||
        key === "midnighttelallowance" ||
        key === "specialvacationallowance" ||
        key === "welfarekaigo" ||
        key === "welfaresyogai" ||
        key === "latecount" ||
        key === "earlycount"
      ) {
        return "disp-none";
      }
      return "";
    },
    isDisabledConfirm(target) {
      console.log("isDisabledConfirm", target);
      const sumrow = this.summary.filter(e => e.affiliationName === "合計");
      console.log(sumrow);
      if (!sumrow) return true;
      console.log(sumrow[0].isconfirm);
      return sumrow[0].isconfirm === target ? true : false;
    },
    isShowApproval() {
      console.log("isShowApproval");
      const sumrow = this.summary.filter(e => e.affiliationName === "合計");
      console.log(sumrow);
      if (!sumrow) return false;
      console.log(sumrow[0].targetUserFlg);
      return sumrow[0].targetUserFlg === "1" ? true : false;
    },
    isDisabledApprovalUser() {
      console.log("isDisabledApprovalUser", this.$route.params.targetUsers);
      return this.$route.params.targetUsers &&
        this.$route.params.targetUsers.length > 0
        ? false
        : true;
    },
    isShowResult() {
      console.log("isShowResult");
      const sumrow = this.summary.filter(e => e.affiliationName === "合計");
      console.log(sumrow);
      if (!sumrow) return false;
      console.log(sumrow[0].approvalStatus);
      if (sumrow[0].approvalStatus === "A1") return true;
      if (sumrow[0].approvalStatus === "B1") return true;
      if (sumrow[0].approvalStatus === "C1") return true;
      if (sumrow[0].approvalStatus === "D1") return true;
      return false;
    },
    isShowCalc() {
      console.log("isShowCalc");
      try {
        const sumrow = this.summary.filter(e => e.affiliationName === "合計");
        console.log(sumrow);
        if (!sumrow) return false;
        console.log(sumrow[0].approvalStatus);
        if (sumrow[0].appoverflag === "1") {
          const authority = this.$store.state.authority;
          if (authority == "1") {
            return true;
          }
        } else if (sumrow[0].appoverflag === "2") {
          return true;
        }
      } catch (e) {
        return false;
      }

      return false;
    },
    async moveNextUser(next) {
      console.log("moveNextUser", next, this.$route.params);

      const index = this.$route.params.targetUsers.findIndex(
        e => e.incode === this.user
      );
      let nextIndex = 0;
      if (next) {
        if (index === this.$route.params.targetUsers.length - 1) {
          nextIndex = 0;
        } else {
          nextIndex = index + 1;
        }
      } else {
        if (index === 0) {
          nextIndex = this.$route.params.targetUsers.length - 1;
        } else {
          nextIndex = index - 1;
        }
      }

      this.user = String(this.$route.params.targetUsers[nextIndex].incode);
      await this.getData(true);
    },
    async moveNextemployee(next) {
      console.log("moveNextemployee", next, this.$route.params);

      const index = this.$route.params.targetemployees.findIndex(
        e => e.incode === this.user
      );
      let nextIndex = 0;
      if (next) {
        if (index === this.$route.params.targetemployees.length - 1) {
          nextIndex = 0;
        } else {
          nextIndex = index + 1;
        }
      } else {
        if (index === 0) {
          nextIndex = this.$route.params.targetemployees.length - 1;
        } else {
          nextIndex = index - 1;
        }
      }

      this.user = String(this.$route.params.targetemployees[nextIndex].incode);
      await this.getData(true);
    },
    async approval() {
      const result = await this.$confirm(
        "承認を実行します。\nよろしいですか？"
      );
      if (!result) return;

      this.$loading();
      try {
        console.log("approval");

        const dt = new Date(
          parseInt(this.date.split("-")[0]),
          parseInt(this.date.split("-")[1]),
          1
        );
        dt.setDate(dt.getDate() - 1);
        const paymentdate =
          dt.getFullYear() +
          "-" +
          ("00" + Number(dt.getMonth() + 1)).slice(-2) +
          "-" +
          ("00" + dt.getDate()).slice(-2);
        const sumrow = this.summary.filter(e => e.affiliationName === "合計");
        let Status = sumrow[0].approvalStatus;
        if (Status.length === 2) {
          Status = Status.substr(0, 1);
        }
        const param = {
          paymentdate: paymentdate,
          employeeId: this.user.code ? this.user.code : this.user,
          approvalStatus: Status
        };

        await this.$put(this.Paths.approvalStatus, param);
        const data = await this.getRecordByUser();
        await this.setAttendanceDetails(data);
        //await this.$info("更新しました。");
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    setdate() {
      this.date = this.$getAiwaServiceDate();
    },
    async getSummaryFixParameters(edit) {
      const userincode = this.user.code ? this.user.code : this.user;
      const selectuser = this.users.filter(e => e.code === userincode);

      const startmonth = new Date(this.Year, this.Month, 16);
      const targetdt = new Date(this.Year, this.Month, this.Day);

      if (targetdt == startmonth) {
        targetdt.setMonth(targetdt.getMonth() + 1);
      } else if (targetdt > startmonth && targetdt.getDate() < 16) {
        targetdt.setMonth(targetdt.getMonth() - 1);
      } else if (targetdt < startmonth && targetdt.getDate() < 16) {
        targetdt.setMonth(targetdt.getMonth() - 1);
      }

      const targetMonth =
        targetdt.getFullYear() +
        "-" +
        ("00" + Number(targetdt.getMonth() + 1)).slice(-2);

      const params = {
        targetmonth: targetMonth,
        user: selectuser[0],
        edit: edit,
        summary: []
      };

      return params;
    },
    backCalc() {
      const path = "/for-calc";
      this.$router.push({
        path: path,
        query: { filter: this.$route.params.filter }
      });
    },
    backShift() {
      const path = "/shifttable";
      this.$router.push({
        path: path,
        query: { filter: this.$route.params.filter }
      });
    },
    backApprovalStatus() {
      const path = "/approval-status";
      this.$router.push({
        path: path,
        query: { filter: this.$route.params.filter }
      });
    },
    async changeTableFilter(val) {
      switch (val) {
        case Filters.All:
          this.clearFilter();
          break;
        case Filters.Attendance:
          this.changeFilter("assistFlagDisplay");
          break;
        case Filters.Assist:
          this.changeFilter("assistFlagDisplay");
          break;
        case Filters.Other:
          this.changeFilter("otherFlagDisplay");
          break;
      }
    },
    clearFilter() {
      for (const [i, e] of this.cols.entries()) {
        if (typeof e === "object" && "filterOptions" in e) {
          this.$set(this.cols[i].filterOptions, "filterValue", "");
        }
      }
    },
    changeFilter(field) {
      this.clearFilter();
      const index = this.cols.findIndex(c => c.field === field);
      if (index !== -1) {
        this.$set(this.cols[index].filterOptions, "filterValue", regex);
      }
    }
  },
  async created() {
    console.log("created", this.date);

    this.$loading();
    try {
      await this.getUsers();
      await this.getShozoku();
      await this.getKkubun();
      this.setdate();
    } finally {
      this.$unloading();
    }

    // 日付の初期値をセット
    const M = Number(DATE.getMonth() + 1);
    if (Number(DATE.getDate()) > 15) {
      //16日以降なら、当月/16 ～ 翌月/15
      if (M == 12) {
        //当月が12月なら翌月は、1月になるので注意
        this.dateFrom = DATE.getFullYear() + "-" + ("00" + M).slice(-2) + "-16";
        this.dateTo = DATE.getFullYear() + 1 + "-01-15";
      } else {
        //12月以外なら、そのまま処理
        this.dateFrom = DATE.getFullYear() + "-" + ("00" + M).slice(-2) + "-16";
        this.dateTo =
          DATE.getFullYear() + "-" + ("00" + (M + 1)).slice(-2) + "-15";
      }
    } else {
      //15日までなら、前月/16 ～ 当月/15
      if (M == 1) {
        //当月が1月なら前月は、12月となるので注意
        this.dateFrom = DATE.getFullYear() - 1 + "-12-16";

        this.dateTo = DATE.getFullYear() + "-" + ("00" + M).slice(-2) + "-15";
      } else {
        //当月が1月以外なら、そのまま処理
        this.dateFrom =
          DATE.getFullYear() + "-" + ("00" + (M - 1)).slice(-2) + "-16";

        this.dateTo = DATE.getFullYear() + "-" + ("00" + M).slice(-2) + "-15";
      }
    }

    this.duplicationflag = false;
    this.backForCalc = false;
    this.backForApprovalStatus = false;
    this.showConfirm = false;
    if (this.$route.params.targetdate && this.$route.params.userincode) {
      console.log("params", this.$route.params);
      this.select = Selects.User;
      this.user = this.$route.params.userincode;
      this.date = this.$route.params.targetdate;
      console.log("params exists", this.user, this.date);
      if (this.$route.params.approvalStatus === "1") {
        this.backForApprovalStatus = true;
      } else {
        if (this.$route.params.type === "0") {
          this.backForCalc = true;
          this.showConfirm = this.$route.params.showConfirm;
        } else if (this.$route.params.type === "1") {
          this.backForShift = true;
        } else {
          this.backForCalc = true;
          this.showConfirm = this.$route.params.showConfirm;
        }
        //this.backForCalc = true;
        //this.showConfirm = this.$route.params.showConfirm;
      }
      await this.getData(true);
    }
  },
  mounted() {
    console.log("mounted");
  }
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 14px;
}

table th:first-child {
  border-radius: 5px 0 0 0;
}

table th:last-child {
  border-radius: 0 5px 0 0;
  border-right: 1px solid #3c6690;
}

table th {
  text-align: center;
  color: white;
  background: linear-gradient(#829ebc, #225588);
  border-left: 1px solid #3c6690;
  border-top: 1px solid #3c6690;
  border-bottom: 1px solid #3c6690;
  box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.3) inset;
  width: 5%;
  padding: 10px 0;
}

table td {
  text-align: center;
  border-left: 1px solid #a8b7c5;
  border-bottom: 1px solid #a8b7c5;
  border-top: none;
  box-shadow: 0px -3px 5px 1px #eee inset;
  width: 5%;
  padding: 10px 0;
}

table td:last-child {
  border-right: 1px solid #a8b7c5;
}

table tr:last-child td:first-child {
  border-radius: 0 0 0 5px;
}

table tr:last-child td:last-child {
  border-radius: 0 0 5px 0;
}
table.vgt-table {
  font-size: 14px;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  table-layout: auto;
}

.detail-table {
  font-size: 12px;
}

.detail-table-header {
  word-break: keep-all;
}

.disp-none {
  display: none;
}

.range-text {
  text-align: center;
  line-height: 50px;
  height: 50px;
}

.table-filter {
  margin-top: 15px;
  margin-left: auto !important;
}

::v-deep #vgt-table > thead > tr:nth-child(2) {
  display: none;
}

.summary-td {
  box-shadow: none !important;
}
</style>
